<template>
	<Page layout="BlankLayout" style="height: 100%">
		<v-sheet dark style="height: 100%">
			<v-container style="height: 100%">
				<v-row>
					<v-col>
						<v-form @submit.prevent="onSubmit">
							<v-card>
								<v-card-title>Event Settings</v-card-title>

								<v-card-text>
									<v-select
										v-model="form.day"
										:items="dayOptions"
										label="Day"
										mandatory
										required
									>
									</v-select>

									<v-select
										v-model="form.state"
										:items="stateOptions"
										label="State"
										mandatory
										required
									></v-select>

									<v-select
										v-model="form.current_session"
										:items="sessionOptions"
										label="Current session"
										required
									></v-select>
								</v-card-text>

								<v-card-actions>
									<v-btn
										type="submit"
										color="primary"
										:loading="isLoading"
										>Save</v-btn
									>
								</v-card-actions>
							</v-card>
						</v-form>
					</v-col></v-row
				>
			</v-container>
		</v-sheet>
	</Page>
</template>

<script>
import axios from "axios";
import { mapGetters, mapState } from "vuex";
export default {
	data() {
		return {
			isLoading: false,
			form: {
				current_session: null,
			},
			dayOptions: [
				{
					text: "Pre Event",
					value: 0,
				},
				{
					text: "Day 1",
					value: 1,
				},
				{
					text: "Day 2",
					value: 2,
				},
				{
					text: "Day 3",
					value: 3,
				},
			],
		};
	},

	async mounted() {},

	computed: {
		...mapState(["event", "agendas"]),
		...mapGetters(["me", "currentSession"]),
		stateOptions() {
			return ["WAITING", "LIVE", "DONE", "ONDEMAND"];
		},
		sessionOptions() {
			const sessions = [];

			[...this.agendas]
				.filter((agenda) => agenda.event_day === this.form.day)
				.forEach((agenda) => {
					agenda.sessions.forEach((session) => {
						sessions.push({ ...session, agenda });
					});
				});

			return sessions.map((session) => {
				let text = `${session.agenda.title} - ${session.name}`;

				if (session.current) {
					text += " (current)";
				}

				return {
					text,
					value: session.id,
				};
			});
		},
	},

	methods: {
		async onSubmit() {
			this.isLoading = true;
			const url = `${process.env.VUE_APP_API_URL}event/change_state`;

			const data = {
				...this.form,
				event_id: 1,
			};

			await axios.post(url, data);
			this.isLoading = false;
		},
	},

	watch: {
		event: {
			immediate: true,
			deep: true,
			handler(event) {
				if (event) {
					this.form = {
						...event,
						current_session: event?.current_session?.id || null,
					};
				}
			},
		},
		currentSession: {
			immediate: true,
			deep: true,
			handler() {
				if (this.currentSession) {
					this.form.current_session = this.currentSession.id;
				}
			},
		},
	},
};
</script>

<style></style>